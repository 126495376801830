@import './assets/scss/variables.module.scss';
@import 'react-toastify/dist/ReactToastify.css';

.Toastify__toast {
  box-shadow: none;
  border-radius: 0;

  .Toastify__toast-body {
    font-weight: 600;
    font-family: Assistant, sans-serif;
    margin: 0;
    padding: 8px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}

.MuiAccordion-root.timesheet-accordion {
  margin-bottom: 0px;
  margin-top: 0px;
  border: 0;
  border-radius: 0;
  background-color: transparent;

  :last-child {
    margin-bottom: 0;
  }

  .summary {
    height: 44px;
    min-height: 64px;

    .MuiAccordionSummary-content {
      align-items: center;
    }
  }

  .details {
    // background: var(--ivory_50);
  }
}

.shift-dates .MuiPickersDay-root:not(.Mui-selected) {
  &.MuiPickersDay-today {
    border: 1px solid var(--primary_10);
  }

  &:not(.Mui-disabled) {
    border: 1px solid var(--primary_grey);
    // Border color for available days
  }
}
