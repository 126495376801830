:root {
  --font_Merriweather: Merriweather;
  --text_header: #141414;
  --primary_grey: #5a6771;
  --lts_temp_grey: #616161;
  --neut_dark_grey: #6e7788;
  --neut_dark_grey_50: rgba(110, 119, 136, 0.5);
  --secondary_DodgerBlue: #0096fb;
  --primary_dark: #36213e;
  --primary_main: #0b406b;
  --secondary_dark: #007ca3;
  --secondary_light: #007ca31a;
  --white: #ffffff;
  --stroke_light: #dee2e6;
  --black_75: #635e5f;
  --text_black_75: rgba(46, 40, 42, 0.75);
  --ivory: #f1f1e6;
  --ivory_25: #fbfbf8;
  --ivory_50: rgba(241, 241, 230, 0.5);
  --disabled_25: rgba(194, 193, 201, 0.25);
  --disabled_50: rgba(194, 193, 201, 0.5);
  --header-drop: 0px 2px 25px rgba(0, 0, 0, 0.1);
  --dropdown_shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --disabled: #c2c1c9;
  --black_50: rgba(46, 40, 42, 0.5);
  --black: #2e282a;
  --header_drop: 0px 2px 25px rgba(0, 0, 0, 0.1);
  --header_drop2: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --backdrop_10: rgba(0, 0, 0, 0.1);
  --success: #6cba60;
  --success_10: rgba(108, 186, 96, 0.1);
  --success_15: rgba(108, 186, 96, 0.15);
  --seconadary_green: #415526;
  --secondar_light_green: #549161;
  --seconadary_green_10: rgba(65, 85, 38, 0.1);
  --success_25: #daedd7;
  --secondary_green: #415526;
  --secondary_green_10: rgba(65, 85, 38, 0.1);
  --error: #bc0e33;
  --state_error: #bc0e32;
  --error_10: rgba(188, 14, 51, 0.1);
  --error_20: #f2cfd6;
  --warning: #d39f06;
  --warning_10: #d39f061a;
  --warning_25: #f4e7c0;
  --primary_8: #ecf0f4;
  --primary_10: rgba(11, 64, 107, 0.1);
  --primary_50: rgba(11, 64, 107, 0.5);
  --primary_25: rgba(11, 64, 107, 0.25);
  --blue_15: #d9efff;
  --blue_25: rgba(0, 150, 251, 0.25);
  --state_20: #f3e9c8;
  --box-shadow_10: #1018281a;
  --box-shadow_20: #1018280f;
  --teal: #00859c;
  --teal_10: rgba(0, 133, 156, 0.1);
  --teal_20: rgba(0, 133, 156, 0.2);
  --green_20: rgba(205, 240, 234, 0.2);
  --green_40: rgba(205, 240, 234, 0.4);
  --olive_green: #9da338;
  --olive_green_10: rgba(157, 163, 56, 0.1);

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: var(--blue_15);
  --toastify-color-success: var(--success_25);
  --toastify-color-warning: var(--warning_25);
  --toastify-color-error: var(--error_20);

  //Used only for colored theme
  --toastify-text-color-info: var(--black);
  --toastify-text-color-success: var(--black);
  --toastify-text-color-warning: var(--black);
  --toastify-text-color-error: var(--black);

  --gradientStartColor: #1e3a8a;
  --gradientEndColor: #93c5fd;
}
