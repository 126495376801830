body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: assistant;
  font-style: normal;
  font-weight: 400;
  src: local('Assistant'), url(./assets/fonts/Assistant-Regular.woff) format('woff');
}

@font-face {
  font-family: assistant;
  font-style: normal;
  font-weight: 600;
  src: local('Assistant'), url(./assets/fonts/Assistant-SemiBold.woff) format('woff');
}

@font-face {
  font-family: assistant;
  font-style: normal;
  font-weight: 700;
  src: local('Assistant'), url(./assets/fonts/Assistant-Bold.woff) format('woff');
}

@font-face {
  font-family: merriweather;
  font-style: normal;
  font-weight: 700;
  src: local('Merriweather'), url(./assets/fonts/Merriweather-Bold.woff) format('woff');
}
